import React, { useEffect, useState, useContext, useCallback } from "react";
import Header from "../Header";
import { AuthContext } from "../Auth";
import { StorageContext } from "../Storage";
import Loading from "../Loading";
import {
  Drawer,
  Paper,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Table,
} from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import moment from "moment";
import "moment/locale/es";
import "./style.css";
import columnsFn from "./columns";
import initialFilters from "./initialFilters";
import SearchButton from "./Buttons/SearchButton";
import AddCustomClientButton from "./Buttons/AddCustomClientButton";
import ResetFiltersButton from "./Buttons/ResetFiltersButton";
import ShowFiltersButton from "./Buttons/ShowFiltersButton";
import FiltersList from "./FiltersList";
import infoWithGmail from "../../helpers/infoWithGmail";
import permissions from "../../helpers/permissions";
import ManagerInputs from "./ManagerInputs";
import DialogAddCustomClient from "./DialogAddCustomClient";
import CodesFilter from "./CodesFilter";
import createIndicadores from "./createIndicadores";

moment.locale("es");

export default function Clients() {
  const auth = useContext(AuthContext);
  const storage = useContext(StorageContext);
  const theme = useTheme();

  // Update Window Size
  const initialSize = window.innerWidth < 760 ? "mobile" : "desktop";
  const [windowSize, setWindowSize] = useState(initialSize);

  useEffect(() => {
    function reportWindowSize() {
      if (window.innerWidth < 760 && windowSize !== "mobile") {
        setWindowSize("mobile");
      } else if (window.innerWidth >= 760 && windowSize !== "desktop") {
        setWindowSize("desktop");
      }
    }

    window.addEventListener("resize", reportWindowSize);

    return () => {
      window.removeEventListener("resize", reportWindowSize);
    };
  }, [windowSize]);

  // Data State
  const [dataLoaded, setDataLoaded] = useState(false);
  const [clients, setClients] = useState(null);
  const [clientsFiltered, setClientsFiltered] = useState(null);
  const [filters, setFilters] = useState(initialFilters);
  const [showFiltersMobile, setShowFiltersMobile] = useState(false);
  const [dialogAddCustomClientOpen, setDialogAddCustomClientOpen] =
    useState(false);

  const applyFilter = useCallback((newFilter, clients) => {
    /**
     * ? README - IMPORTANT!!!!!
     * ! When adding new special filters (N, >0...), always include them on "Rest Filters". It's not necessary for normal filters (SI/S)
     *
     * ! Never make a filter like client[var] !== "" because customClients are === undefined so it will include customClients
     */

    // Set filters for fast input response
    setFilters(newFilter);

    let results = [...clients];

    // Select Comercial (only for Manager)
    if (newFilter.comercial) {
      results = results.filter(
        (client) => client["COMERCIAL"] === newFilter.comercial
      );
    }

    // Select Codigo FFVV
    if (newFilter.codes) {
      results = results.filter(
        (client) => client["DIS_CODIGO_FV"] === newFilter.codes
      );
    }

    // Search
    if (newFilter.search !== "") {
      const searchNormalized = newFilter.search
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .toLowerCase();

      results = results.filter((client) => {
        const idFiscalNormalized = client["CLI_ID_FISCAL"]
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .toLowerCase();

        const nombreNormalized = client["CLI_NOMBRE"]
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .toLowerCase();

        const direccionNormalized = client["CLI_DIRECCION"]
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .toLowerCase();

        if (
          idFiscalNormalized.includes(searchNormalized) ||
          nombreNormalized.includes(searchNormalized) ||
          direccionNormalized.includes(searchNormalized)
        ) {
          return true;
        } else {
          return false;
        }
      });
    }

    // Visitado > 1 año
    if (newFilter["lastVisit"]) {
      results = results.filter((client) => {
        const lastVisit = client["lastVisit"];
        if (lastVisit === "") {
          return true;
        } else if (
          moment(client["lastVisit"], "DD MMMM 'YY").isBefore(
            moment().subtract(12, "months")
          )
        ) {
          return true;
        } else {
          return false;
        }
      });
    }

    // Fuera de Gevico
    if (newFilter["outsideGevico"]) {
      results = results.filter((client) => client["CLI_GEVICO"] === "N");
    }

    // Portas > 0
    if (newFilter["portasNumero"]) {
      results = results.filter(
        (client) => Number.parseInt(client["PORTAS::Numero"]) > 0
      );
    }

    // Portados 2023
    if (newFilter["MICRO::Portados 2023"]) {
      results = results.filter(
        (client) => Number.parseInt(client["MICRO::Portados 2023"]) > 0
      );
    }

    // ? TEMPLATES
    /*
     * > 0
    if (newFilter["customName"]) {
      results = results.filter(
        (client) => Number.parseInt(client["csvName"]) > 0
      );
    }
    *
    * N
    if (newFilter["customName"]) {
      results = results.filter(
        (client) => client["csvName"] === "N"
      );
    }
    */

    // ! Remember to include this custom filters below
    // Fondos Europeos Fases 1, 2, 3 (este filtro lo engloba)
    if (newFilter["fondosEuropeos"]) {
      results = results.filter(
        (client) =>
          client["TESA::FONDOS KIT Fase 1"] === "SI" ||
          client["TESA::FONDOS KIT Fase 2"] === "SI" ||
          client["TESA::FONDOS KIT Fase 3 Ampliación PO Kit Digital PE"] ===
            "SI" ||
          client["TESA::FONDOS KIT Fase 3 NUEVO BONO"] === "SI" ||
          client["TESA::FONDOS KIT Fase 3 UPGRADE"] === "SI" ||
          client["TESA::FONDOS KIT Fase3 NUEVO BONO AUTÓNOMOS"] === "SI"
      );
    }

    // No tienen FE Cloud
    if (newFilter["noCloud"]) {
      results = results.filter(
        (client) => client["SCRIPT::Fusión Empresas Cloud (S/N)"] === "N"
      );
    }

    // ! Remember to include the above filters
    // Rest filters
    for (const key in newFilter) {
      if (
        newFilter[key] &&
        key !== "comercial" &&
        key !== "codes" &&
        key !== "search" &&
        key !== "lastVisit" &&
        key !== "outsideGevico" &&
        key !== "fondosEuropeos" &&
        key !== "portasNumero" &&
        key !== "noCloud" &&
        key !== "MICRO::Portados 2023"
      ) {
        results = results.filter(
          (client) =>
            client[key] === "SI" || client[key] === "S" || client[key] === "1"
        );
      }
    }

    setClientsFiltered(results);
  }, []);

  // Get Clients & CustomClients from Storage context
  useEffect(() => {
    // Get clients and customClients
    const clients = storage?.clients;
    const customClients = storage?.customClients;

    let allClients = clients;

    // For avoid error when customClients is empty
    if (customClients) {
      allClients = clients.concat(customClients);
    }

    if (allClients !== undefined) {
      setClients(allClients);
      // Necessary for database updates
      applyFilter(filters, allClients);
      setDataLoaded(true);
    }
  }, [storage, applyFilter, filters]);

  const columns = columnsFn(windowSize, auth.email);

  function handleDialogAddCustomClientOpen() {
    setDialogAddCustomClientOpen(true);
  }

  function handleClientFileOpen(_event, row) {
    window.open(`/ficha-cliente/${row.vat}`, "_blank");
  }

  function handleResetFilters() {
    setClientsFiltered(clients);
    setFilters(initialFilters);
  }

  function handleSearch({ target }) {
    const newSearch = { ...filters };
    newSearch.search = target.value;
    applyFilter(newSearch, clients);
  }

  function handleSwitch({ target }) {
    const keyFilter = target.name;
    const newFilter = { ...filters };
    newFilter[keyFilter] = !newFilter[keyFilter];
    applyFilter(newFilter, clients);
  }

  function handleManagerSelect({ target }) {
    const newSelection = { ...filters };
    newSelection.comercial = target.value;
    applyFilter(newSelection, clients);
  }

  function handleCodesSelect({ target }) {
    const newSelection = { ...filters };
    newSelection.codes = target.value;
    applyFilter(newSelection, clients);
  }

  function handleShowFilters() {
    return setShowFiltersMobile(!showFiltersMobile);
  }

  function handleDialogAddCustomClientClose() {
    setDialogAddCustomClientOpen(false);
  }

  function createData({ code, name, vat, lastVisit, comercial, indicadores }) {
    return {
      code,
      comercial,
      name,
      vat,
      lastVisit,
      indicadores,
    };
  }

  if (dataLoaded) {
    // Create rows
    const rows = [];

    clientsFiltered.forEach((client) => {
      const row = createData({
        code: client["DIS_CODIGO_FV"].slice(5),
        name: client["CLI_NOMBRE"],
        vat: client["CLI_ID_FISCAL"],
        lastVisit: client["lastVisit"],
        comercial: infoWithGmail(client["COMERCIAL"]).name,
        indicadores: createIndicadores(client),
      });

      rows.push(row);
    });

    return (
      <>
        <Header />
        <div className="clients__container">
          {windowSize === "desktop" ? (
            <div className="clients__left-column">
              <SearchButton
                value={filters["search"]}
                handleOnChange={handleSearch}
              />
              {permissions("manager", auth.email) ? (
                <ManagerInputs
                  value={filters["comercial"]}
                  handleOnChange={handleManagerSelect}
                />
              ) : null}
              <CodesFilter
                value={filters["codes"]}
                handleOnChange={handleCodesSelect}
              />
              <AddCustomClientButton
                handleOnClick={handleDialogAddCustomClientOpen}
              />
              <ResetFiltersButton handleOnClick={handleResetFilters} />
              <FiltersList handleSwitch={handleSwitch} filters={filters} />
            </div>
          ) : (
            <div className="clients__center-column">
              <AddCustomClientButton
                handleOnClick={handleDialogAddCustomClientOpen}
              />
              <ShowFiltersButton handleOnClick={handleShowFilters} />
              <Drawer
                style={{ width: 300 }}
                anchor="right"
                open={showFiltersMobile}
                onClose={handleShowFilters}
              >
                <div className="clients__drawer-container">
                  <SearchButton
                    value={filters["search"]}
                    handleOnChange={handleSearch}
                  />
                  {permissions("manager", auth.email) ? (
                    <ManagerInputs
                      value={filters["comercial"]}
                      handleOnChange={handleManagerSelect}
                    />
                  ) : null}
                  <CodesFilter
                    value={filters["codes"]}
                    handleOnChange={handleCodesSelect}
                  />
                  <ResetFiltersButton handleOnClick={handleResetFilters} />
                  <FiltersList handleSwitch={handleSwitch} filters={filters} />
                </div>
              </Drawer>
            </div>
          )}
          <div className="clients__right-column">
            <div
              className="clients__table-info"
              style={{ color: theme.palette.primary.main }}
            >
              Mostrando{" "}
              <span className="clients__table-info--important">
                {clientsFiltered.length}
              </span>{" "}
              de{" "}
              <span className="clients__table-info--important">
                {clients.length}
              </span>{" "}
              clientes
            </div>
            <Paper className="clients__table-paper">
              <TableContainer className="clients__table-container">
                <Table stickyHeader>
                  <TableHead>
                    <TableRow>
                      {columns.map((column, index) => (
                        <TableCell
                          key={`${column.id}-${index}`}
                          align={column.align}
                          className="diary__table-head"
                          style={{
                            minWidth: column.minWidth,
                            backgroundColor: theme.palette.primary.main,
                            borderBottom: `1px solid ${theme.table.border}`,
                          }}
                        >
                          {column.label}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows.map((row, index) => (
                      <TableRow
                        hover
                        tabIndex={-1}
                        key={index}
                        onClick={(event) => handleClientFileOpen(event, row)}
                      >
                        {columns.map((column, index) => {
                          const value = row[column.id];
                          return (
                            <TableCell
                              key={`${column.id}-${index}`}
                              align={column.align}
                              className="diary__table-row"
                              style={{
                                borderBottom: `1px solid ${theme.table.border}`,
                              }}
                            >
                              {column.format ? column.format(row) : value}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </div>
        </div>
        <DialogAddCustomClient
          open={dialogAddCustomClientOpen}
          handleCloseFromClients={handleDialogAddCustomClientClose}
        />
      </>
    );
  } else {
    return (
      <>
        <Header />
        <Loading />
      </>
    );
  }
}
