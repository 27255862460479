import React from "react";
import { useTheme } from "@material-ui/core/styles";
import { excelDateFormat } from "../../helpers/excelDateFormat";

function Tags({ data }) {
  const theme = useTheme();

  const tagsBlocks = (blocks) => {
    return blocks.map(({ title, tags }, index) => {
      const tagsGroup = tags.map(
        ({ displayName, dataSource, condition }, index) => {
          if (
            dataSource === condition ||
            (condition === "any" &&
              dataSource !== "0" &&
              dataSource !== "" &&
              dataSource !== "NO" &&
              dataSource !== undefined)
          ) {
            return (
              <div
                className="client-file-tags__block__tag"
                style={{
                  color: theme.palette.primary.main,
                  border: `1px solid ${theme.palette.primary.main}`,
                }}
                key={index}
              >
                {displayName}
              </div>
            );
          } else {
            return "";
          }
        }
      );

      return (
        <div className="client-file-tags__block__group" key={index}>
          <div className="client-file-tags__block__title">{title}</div>
          <div className="client-file-tags__block__tags-list">{tagsGroup}</div>
        </div>
      );
    });
  };

  const tesaItems = [
    "TESA::CAPTAR Autónomos",
    "TESA::CAPTAR FD Black Friday 360e",
    "TESA::CAPTAR FD Clientes Prioritarios",
    "TESA::CAPTAR FD Promo 20% 18 meses",
    "TESA::CAPTAR FD Promo 30% 18 meses",
    "TESA::CAPTAR FD Sedes 4G",
    "TESA::CAPTAR FD Segundas sedes",
    "TESA::CAPTAR FD Segundas sedes Prioritarios",
    "TESA::CAPTAR No Clientes",
    "TESA::CAPTAR No Clientes Bluevia",
    "TESA::Cliente promo F. Digital 30% 2 años fin o prox fin NO ofrecer Promo",
    "TESA::Clientes para Reblindar (informativa)",
    "TESA::EQUIPAMIENTO Alta Micro",
    "TESA::EQUIPAMIENTO Alta Smartphone",
    "TESA::EQUIPAMIENTO iPhone 15",
    "TESA::EQUIPAMIENTO Propensión a contratar R2R",
    "TESA::FIBRA Incentivos Migración Cobre",
    "TESA::FIBRA Migración",
    "TESA::FONDOS KIT Fase 1",
    "TESA::FONDOS KIT Fase 2",
    "TESA::FONDOS KIT Fase 3",
    "TESA::GESTIÓN Cambio de cartera",
    "TESA::MIGRAR FD cobre a FD 4G Promo",
    "TESA::NOVAR FD con promo 30% 12 meses",
    "TESA::NOVAR FD Reblindaje",
    "TESA::NOVAR FD sedes pequeñas",
    "TESA::SOLAR360 Captación",
  ];

  const blocksA = [
    {
      title: "Carterización",
      tags: [
        {
          displayName: "De Gevico",
          dataSource: data["CLI_GEVICO"],
          condition: "S",
        },
        {
          displayName: "Fuera de Gevico",
          dataSource: data["CLI_GEVICO"],
          condition: "N",
        },
        {
          displayName: "Cuasiclientes",
          dataSource: data["MICRO::Cuasiclientes"],
          condition: "S",
        },
        {
          displayName: "Cambio de cartera",
          dataSource: data["Cambio de cartera"],
          condition: "SI",
        },
      ],
    },
    {
      title: "Campañas TESA",
      tags: tesaItems.map((item) => {
        return {
          displayName: item.split("TESA::")[1].replace(/_/g, " "),
          dataSource: data[item],
          condition: "SI",
        };
      }),
    },
    {
      title: "Parque Móvil",
      tags: [
        {
          displayName: `Fact. Media 3 meses: ${data["SCRIPT::Media NM 3 meses"]}€`,
          dataSource: data["SCRIPT::Media NM 3 meses"],
          condition: "any",
        },
        {
          displayName: `${data["SCRIPT::Total Líneas NM"]} Líneas`,
          dataSource: data["SCRIPT::Total Líneas NM"],
          condition: "any",
        },
        {
          displayName: "Móviles Portados",
          dataSource: data["SCRIPT::Móviles Portados (S/N)"],
          condition: "S",
        },
      ],
    },
  ];

  const blocksB = [
    {
      title: "Parque Fijo",
      tags: [
        {
          displayName: `Fact. Media 3 meses: ${data["SCRIPT::Media NF 3 meses"]}€`,
          dataSource: data["SCRIPT::Media NF 3 meses"],
          condition: "any",
        },
        {
          displayName: `${data["SCRIPT::Líneas VOZ NF"]} Líneas`,
          dataSource: data["SCRIPT::Líneas VOZ NF"],
          condition: "any",
        },
        {
          displayName: "Fusión Empresas Básico",
          dataSource: data["SCRIPT::Fusión Empresas Básico (S/N)"],
          condition: "S",
        },
        {
          displayName: "Fusión Empresas Cloud",
          dataSource: data["SCRIPT::Fusión Empresas Cloud (S/N)"],
          condition: "S",
        },
        {
          displayName: "Fusión Empresas Pro",
          dataSource: data["SCRIPT::Fusión Empresas Pro (S/N)"],
          condition: "S",
        },
        {
          displayName: "IP Estática",
          dataSource: data["SCRIPT::IP Estática (S/N)"],
          condition: "S",
        },
        {
          displayName: `Blindar con: ${data["SCRIPT::Producto Blindaje"]}`,
          dataSource: data["SCRIPT::Producto Blindaje"],
          condition: "any",
        },
      ],
    },
    {
      title: "Parque TMA / Micro",
      tags: [
        {
          displayName: `${data["SCRIPT::Lineas TMA"]} TMA`,
          dataSource: data["SCRIPT::Lineas TMA"],
          condition: "any",
        },
      ],
    },
    {
      title: "Parque TI",
      tags: [
        {
          displayName: `Facturación Media 3 meses: ${data["SCRIPT::Media TI 3 meses"]}€`,
          dataSource: data["SCRIPT::Media TI 3 meses"],
          condition: "any",
        },
      ],
    },
    {
      title: "Order",
      tags: [
        {
          displayName: `Parque móvil: ${data["ORDER::Parque Móvil"]}`,
          dataSource: data["ORDER::Parque Móvil"],
          condition: "any",
        },
        {
          displayName: `Parque fijo: ${data["ORDER::Parque fijo"]}`,
          dataSource: data["ORDER::Parque fijo"],
          condition: "any",
        },
        {
          displayName: "Tiene Cloud",
          dataSource: data["ORDER::Cloud"],
          condition: "any",
        },
        {
          displayName: `Comentario previo: ${data["ORDER::Comentario"]}`,
          dataSource: data["ORDER::Comentario"],
          condition: "any",
        },
      ],
    },
    {
      title: "Portabilidades",
      tags: [
        {
          displayName: `Líneas portadas: ${data["PORTAS::Numero"]}`,
          dataSource: data["PORTAS::Numero"],
          condition: "any",
        },
        {
          displayName: `Líneas portadas 2023: ${data["MICRO::Portados 2023"]}`,
          dataSource: data["MICRO::Portados 2023"],
          condition: "any",
        },
        {
          displayName: `Fecha: ${excelDateFormat(data["PORTAS::Fecha"])}`,
          dataSource: data["PORTAS::Fecha"],
          condition: "any",
        },
        {
          displayName: `Operador: ${data["PORTAS::Operador"]}`,
          dataSource: data["PORTAS::Operador"],
          condition: "any",
        },
      ],
    },
  ];

  return (
    <div className="client-file-tags">
      <div className="client-file-tags__block">{tagsBlocks(blocksA)}</div>
      <div className="client-file-tags__block">{tagsBlocks(blocksB)}</div>
    </div>
  );
}

export default Tags;
