const initialFilters = {
  search: "",
  comercial: "",
  codes: "",
  lastVisit: false,
  outsideGevico: false,
  // Deletable Filters
  noCloud: false,
  fondosEuropeos: false,
  portasNumero: false,
  "MICRO::Portados 2023": false,
};

export default initialFilters;
